import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import HomePagePages from '../Home';

import checkOutPage from '../CheckOut';
import menuPage from '../Menu';
//import Pdf from '../Pdf';




import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import * as ROUTES from '../../constants/routes';

import { withAuthentication } from '../Session';





const App = () => (
  <Router>
    <div>
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.LANDING} component={HomePagePages} />
      <Route   exact path={ROUTES.CHECKOUT} component={checkOutPage} />
      <Route   exact path={ROUTES.MENU} component={menuPage} />




    </div>
  </Router>
);

export default withAuthentication(App);
