import React, { Component } from 'react';


import API from "../../util/Api.js"


function decimal(num) {
  var n = num.toFixed(2);
  return n
}

class Cart extends Component {

  constructor(props) {
    super(props);
    this.state = {
				total:0,
				subTotal:0,
        cart:null,
    };


  }



  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.props.cart !== propsPrecedenti.cart) {
      this.setState({cart:this.props.cart})
    }
  }

  componentDidMount() {

    this.setState({cart:this.props.cart})
  }

	changeQty(piatto,type){

    const ApiTags= new API ({url:'/incrementItem'});
  	var entity= {name:"incrementItem"};
  	ApiTags.createEntity(entity);
  	var ppp = {};
  	let obj = {
  		"id" : this.props.cart._id,
  		"idp" :piatto._id,
  		"decrem": type  ? true : false,
  	 }
  	ApiTags.endpoints.incrementItem
  	.update(obj,ppp)
  	.then(res=>{

      this.setState({cart:res.data})

  	});

		/*if(type){
			piatto.qty = piatto.qty - 1;
			piatto.total = piatto.prezzo * piatto.qty;

		}else {
			piatto.qty = piatto.qty + 1;
			piatto.total = piatto.prezzo * piatto.qty;
		}*/
	}

  pezziTotali(){
    let {cart} = this.state;
    let totale = 0;
    if(cart){
      cart.items.forEach((x,i)=>{
  			if(x.qty && x.qty > 0){
          totale = totale + x.qty;
        }

  		})
    }

		return totale
  }
	subTotal(sped){
    let {cart,item} = this.state;
		let subTotal = 0;

    if(cart && cart.type != 'take_away'){
      cart.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.sconto > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.sconto/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

  		})
    }else {
      cart.items.forEach((x,i)=>{

        if(x.qty && x.qty > 0){
            if(x.scontoAsp > 0){
              subTotal = subTotal + ((x.qty * x.prezzo)-(x.qty * x.prezzo)*x.scontoAsp/100);
            }else {
              subTotal = subTotal + (x.qty * x.prezzo);
            }

        }

  		})
    }




    if(cart && cart.type != 'take_away'){
      if(sped){
        subTotal = subTotal + Number(this.props.speseSped);
      }
    }
		return decimal(subTotal)
	}


pricePiatto(piatto){

  let {cart} = this.state;
  let type = cart.type;
  let totalP = piatto.qty * piatto.prezzo;
  if(type == 'take_away'){
    if(piatto.scontoAsp > 0){
      return <span><span style={{textDecoration: 'line-through'}}>{decimal(totalP)}€</span><span style={{color:'orange',marginLeft:5}}>{decimal(totalP - (totalP*piatto.scontoAsp/100))}€</span></span>
    }else {
      return decimal(totalP)
    }

  }else {
    if(piatto.sconto > 0){
      return <span><span style={{textDecoration: 'line-through'}}>{decimal(totalP)}€</span><span style={{color:'orange',marginLeft:5}}>{decimal(totalP - (totalP*piatto.sconto/100))}€</span></span>
    }else {
      return decimal(totalP)
    }
  }

}




render() {

    let {cart} = this.state;
    return (
      <div style={{width:'100%'}}>

        <div className='cartCloseMob'>
          <p>Il mio carrello</p>

        </div>
        <div className='DescckC'>
          {this.props.auth ? (
            <a href='/checkout/payment'><div  className='vaiCassa' style={{textAlign:'center',padding:20}}>Vai alla cassa  <span style={{marginLeft:10}} className='conterC'>{this.pezziTotali()}</span> </div></a>

          ) : (
            <a href='/signin'><div  className='vaiCassa' style={{textAlign:'center',padding:20}}>Vai alla cassa  <span tyle={{marginLeft:10}} className='conterC'>{this.pezziTotali()}</span> </div></a>

          )}
        </div>

				<div className='boxList'>{cart ? cart.items.map((piatto,i)=>{
					return (
            piatto.qty > 0 ? (
              <div style={{display:'flex',padding:13}}>
  							<div style={{display:'flex',fontSize:24,marginTop: 12,  marginRight: 20}}>
  								<div onClick={()=>this.changeQty(piatto,true)} style={{marginRight:8,fontWeight:900,cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path className='colorp' d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></div>
  								<div style={{fontSize:17,color:'#828585',lineHeight: '34px'}}>{piatto.qty}</div>
  								<div onClick={()=>this.changeQty(piatto)} style={{marginLeft:8,fontWeight:900,cursor:'pointer',marginTop: 0}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16px" height="16px"><path className='colorp' d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"/><path fill="#FFF" d="M391.5,214.5H297v-93.9c0-4-3.2-7.2-7.2-7.2h-68.1c-4,0-7.2,3.2-7.2,7.2v93.9h-93.9c-4,0-7.2,3.2-7.2,7.2v69.2c0,4,3.2,7.2,7.2,7.2h93.9v93.4c0,4,3.2,7.2,7.2,7.2h68.1c4,0,7.2-3.2,7.2-7.2v-93.4h94.5c4,0,7.2-3.2,7.2-7.2v-69.2C398.7,217.7,395.4,214.5,391.5,214.5z"/></svg></div>
  							</div>
  							<p style={{fontSize:16}}>{piatto.nome}</p>
  							<p style={{marginLeft:'auto'}}>{this.pricePiatto(piatto)}</p>
  						</div>
            ) : null

					)

				}) : null}</div>
      {cart && cart.items.length > 0 ? (
				<div style={{padding:10}}>
					<hr className='barron'></hr>
					<div style={{display:'flex',paddingTop:24,fontSize:17,fontWeight:400}}><p>Subtotale</p><p style={{marginLeft:'auto'}}>€ {this.subTotal()}</p></div>
					<div>
            {cart.type != 'take_away' ? <div style={{display:'flex'}}><p>Spese di consegna</p><p style={{marginLeft:'auto'}}>€ {decimal(Number(this.props.speseSped))}</p></div> : null}
					<hr></hr>
						<div style={{display:'flex',fontSize:16,fontWeight:900,marginTop:20}}><p>Totale</p><p style={{marginLeft:'auto'}}>€ {this.subTotal(true)}</p></div>
					</div>
				</div>
			) : <p style={{textAlign:'center',color:'#ddd'}}>Il tuo carrello è vuoto</p>}

      <div className='mobileC'>
      {this.props.auth ? (
        <a href='/checkout/payment'><div  className='vaiCassa' style={{textAlign:'center',padding:20,marginBottom:20}}>Vai alla cassa  <span style={{marginLeft:10}} className='conterC'>{this.pezziTotali()}</span> </div></a>

      ) : (
        <a href='/signin'><div  className='vaiCassa' style={{textAlign:'center',padding:20,marginBottom:20}}>Vai alla cassa  <span tyle={{marginLeft:10}} className='conterC'>{this.pezziTotali()}</span> </div></a>

      )}
      </div>
      </div>



    )
  }
}

//const condition = authUser => !!authUser;

export default Cart
