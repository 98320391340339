import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import API from "../../util/Api.js"
import SignUp from "../SignUp"
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Footer from '../Footer';


const SignInPage = () => (
            <div>
              <SignInForm />
            </div>


    /*<SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordForgetLink />*/


);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  auth:null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }

  GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

      this.setState({carts:res.data});
    });
  }

  CreatecartS(){
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{

      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }

  componentDidMount() {
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  subTotal(){
  	let {carts} = this.state;

  	let subTotal = 0;
  	if(carts){
  		carts.items.forEach((x,i)=>{

  			subTotal = subTotal + (x.qty * x.prezzo);
  		})
  	}

  	return subTotal
  }

  render() {
    const {auth, email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div>
        <div className='barra'>
          <div className='specialBar'></div>
          <div style={{background:'transparent',position:'relative',zIndex:1,display:'flex'}} className='containerFix'>
            <div>
              <a href='/'><h1 style={{color:'white'}}>Yù Restaurant</h1></a></div>
            <div style={{display:'flex',marginLeft:'auto',marginTop: 8}}>
              <div className='buttonActionBar ' style={{display: 'inline-table',fontWeight:300}}><svg xmlns="http://www.w3.org/2000/svg" className='colorp' width="16" height="16" viewBox="0 0 24 24"><path d="M4.558 7l4.701-4.702c.199-.198.46-.298.721-.298.613 0 1.02.505 1.02 1.029 0 .25-.092.504-.299.711l-3.26 3.26h-2.883zm12.001 0h2.883l-4.702-4.702c-.198-.198-.459-.298-.72-.298-.613 0-1.02.505-1.02 1.029 0 .25.092.504.299.711l3.26 3.26zm7.441 2v2h-.642c-.534 0-1.022.305-1.257.786l-4.101 10.214h-12l-4.101-10.216c-.234-.48-.722-.784-1.256-.784h-.643v-2h24zm-15 4c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.447-1-1-1s-1 .448-1 1v5c0 .552.447 1 1 1s1-.448 1-1v-5z"/></svg> € {this.subTotal()},00 </div>
              {auth ? (
                <Link to="/menu"><div className='buttonActionBar' style={{display: 'inline-table',fontWeight:300}}>Menu</div></Link>
              ) : (
                <div className='buttonActionBar' style={{display: 'inline-table',fontWeight:300}}><Link to="/signin">Log in</Link></div>
              )}

            </div>
          </div>
          <div  className='gridSin container restaurant' >
            <div style={{marginTop:40, textAlign: 'center',padding:20, background:'#fff',maxWidth: 600,marginLeft: 'auto',marginRight:'auto',width:'80%'}}>
              <h2>Accedi</h2>
              <form onSubmit={this.onSubmit}>
                <div>
                  <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
                    <label>Email</label>
                  </div>
                  <input
                    style={{fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                  />
                </div>
                <div>
                  <div style={{textAlign: 'left', marginBottom: 8, fontSize: 13}}>
                    <label>Password</label>
                  </div>
                  <input
                    style={{background:'#fff', fontSize:'16px', width:'90%',padding:10,border: '1px solid #ddd',marginBottom:15}}
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                  />
                </div>
                <div style={{maxWidth:400,marginLeft:'auto',marginRight:'auto',marginTop:20}}><button
                   style={{marginLeft:0}}
                  className='buttonAction'
                  disabled={isInvalid} type="submit">
                  Sign In
                </button></div>

                {error && <p>{error.message}</p>}
              </form>

            </div>
            <div style={{marginTop:40, textAlign: 'center',padding:20, background:'#fff',maxWidth: 600,marginLeft: 'auto',marginRight:'auto'}}>
                <h2>Registrati</h2>
                  <SignUp />
              </div>


          </div>
        </div>
        <div className='footr'>
          <Footer />
				</div>
      </div>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {

        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: [],
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
