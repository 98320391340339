import React, { Component } from 'react';
import Logo from '../img/logo.png'

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };


  }



  componentDidUpdate(propsPrecedenti) {
  // Utilizzo tipico (non dimenticarti di comparare le props):

  }

  componentDidMount() {

  }







render() {
    return (
      <div style={{width:'100%'}}>
        <div style={{textAlign:'center'}}><img style={{width:200,marginTop:30}} src={Logo} /></div>
      </div>



    )
  }
}

//const condition = authUser => !!authUser;

export default Footer
